// This is the eventual home of all the main
// application code for tmp.org. During the
// transition, both this index and the sprockets
// app/app.js will be loaded on every page.

import './analytics/ga.js';
import './analytics/honeybadger.js';
import './analytics/mailchimpgoaltracking.js';
import './analytics/pagevisibility.js';
import './analytics/record.js';
import './analytics/scrolldepth.js';
import './animations/animations.js';
import './dynamic-sizing/dynamic-sizing.js';
import './dynamic-sizing/responsive-iframes.js';
import './external-services/doccloud.js';
import './house-ads/house-ads.js';
import './legacy/emailsignup-deprecated.js';
import './menu/menu.js';
import './posts/headers/vertical-header.js';
import './scrolling/hotzone.js';
import './scrolling/jump.js';
import './scrolling/global-scroll-service.js';
import './toasts/toastnotifications.js';
import './util/cookiehelpers.js';
import '$components/components.js';

import {
  dynamicRenderClassToElement,
  whenElementExists,
  loadScript,
} from '$shared/utils.js';

// Self-contained renderers
whenElementExists('.post-annotation-2-selector', () =>
  import('./shortcodes/annotation_2.js')
);
whenElementExists('[data-audioplayer="v1"]', () =>
  import('./shortcodes/audioplayer_1.js')
);
whenElementExists('[data-shortcode-form]', () =>
  import('./shortcodes/form.js')
);
whenElementExists('#test-partner-url-button', () =>
  import('./legacy/partnersetup.js')
);
whenElementExists('.topshelf, .topshelf-collection', () =>
  import('./stream/topshelf.js')
);
whenElementExists('.compact-text-js', () => import('./util/textcompacter.js'));
whenElementExists('#manage-donations-email', () =>
  import('./support/manage.js')
);
whenElementExists('clipboard-copy', () =>
  import('../shared/clipboard-copy.js')
);
whenElementExists('include-fragment', () =>
  import('@github/include-fragment-element')
);

whenElementExists('img.lazyload,video.lazyload,iframe.lazyload', () =>
  import('./media/lazyload.js').then(({ init }) => init())
);
whenElementExists('[data-background-image]', () =>
  import('./media/lazy-background-images.js').then(({ init }) => init())
);

whenElementExists('.share-button', () => import('./analytics/sharetools.js'));

// Class based renderers
dynamicRenderClassToElement('.email-signup-v2-js', () =>
  import('./subscribe/emailsignupservice.js')
);
dynamicRenderClassToElement(
  '.tmp-video-player-js',
  () => import('./video/video-player.js'),
  { skipRender: true }
);
dynamicRenderClassToElement('.static-menu', () =>
  import('./static-pages/menu.js')
);
dynamicRenderClassToElement('[data-toc]', () =>
  import('./table-of-contents/table-of-contents.js')
);
dynamicRenderClassToElement('.photo-overlay-header-post', () =>
  import('./posts/headers/photooverlay.js')
);

// External scripts to conditionally load
whenElementExists('.twitter-tweet', () => {
  loadScript('https://platform.twitter.com/widgets.js');
});
whenElementExists('.fb-post', () => {
  loadScript('https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2');
});
whenElementExists('.instagram-media', () => {
  loadScript('https://www.instagram.com/embed.js');
});
